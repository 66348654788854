import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./hubadmin/layout/layout.component";
import { SigninComponent } from "./common/signin/signin.component";
import { AuthGuardService } from "./services/auth/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "signin",
    pathMatch: "full",
  },
  {
    path: "signin",
    component: SigninComponent,
  },
  {
    path: "hubAdmin",
    loadChildren: () =>
      import("./hubadmin/hubadmin.module").then((m) => m.HubadminModule),
  },
  {
    path: "agent",
    loadChildren: () =>
      import("./agent/agent.module").then((m) => m.AgentModule),
  },
  {
    path: "teamleader",
    loadChildren: () =>
      import("./team-leader/team-leader.module").then(
        (m) => m.TeamLeaderModule
      ),
  },
  {
    path: "reporting-dashboard",
    loadChildren: () =>
      import("./reporting-dashboard/reporting-dashboard.module").then(
        (m) => m.ReportingDashboardModule
      ),
  },
  {
    path: "restaurants",
    loadChildren: () =>
      import("./restaurants-program/restaurants-program.module").then(
        (m) => m.RestaurantsProgramModule
      ),
  },
  {
    path: "redemption",
    loadChildren: () =>
      import("./redemption/redemption.module").then((m) => m.RedemptionModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
